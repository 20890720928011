if (typeof signee === 'undefined') {
    var signee = {};
} else {
    signee = {};
}


function signPage(target) {

    if (signee.name) {
        //target.val(signee.name);
        //target.attr("disabled", true);
        
        //target.replaceWith(signee.name);
        var input = $(target.parent().find("input[type=text]")[0])
        input.val(signee.name);
        input.show();
        target.hide();
        
        return;
    }

    $("#divSignature").dialog({
        buttons: {
            sign: function () {
                signee.name = $("#signeeName").val();
                signee.email = $("#signeeEmail").val();

                if (!(signee.name && signee.email)) return;

                //target.val(signee.name);
                //target.attr("disabled", true);
                //target.replaceWith(signee.name);
                var input = $(target.parent().find("input[type=text]")[0])
                input.val(signee.name);
                input.show();
                target.hide();
                $(this).dialog("close");
            }
        }
    });
}

function submit() {
    var document_id = $('#document_id').val();
    var data = {};

    $.post(
        window.location.href,
        data
    ).done(function (response) {
        try {
            fireEvent({
                status: true,
                document_id: document_id,
                signee_email: signee.email
            });
        } catch (ex) {
            fireEvent({status: false});
            alert("Error submitting document data");
        }
    }).fail(function () {
        fireEvent({status: false});
        alert("Error submitting document data");
    });
}

function fireEvent(detail) {
    window.parent.document.dispatchEvent(new CustomEvent('DocumentSignedEvent', {
        detail: detail
    }));
}

$(function () {
    $(".PAGE_SIGNATURE").click(function (event) {
        signPage($(event.target));
    });

    $(".FULL_SIGNATURE").click(function (event) {
        submit();
    });
});
