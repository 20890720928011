function encodeUrlWithParams(url) {
    if (!url) {
        return;
    }

    var urlArray = url.split('?');
    var baseUrl = urlArray[0];

    if (urlArray.length > 1) {
        baseUrl += '?' + encodeParams(urlArray[1]);
    }

    return baseUrl;
}

function encodeParams(params) {
    if (!params) {
        return;
    }

    var isString = typeof params === 'string' || params instanceof String;
    var isObject = typeof params === 'object';

    if (isString) {
        return 'q=' + customB64Encode(params);
    } else if (isObject) {
        return {
            'q': customB64Encode(JSON.stringify(params))
        };
    }
}

function customB64Encode(str) {
    var c1 = generateCode(1, CODE_LCHARS + CODE_UCHARS);
    var c2 = generateCode(1, CODE_LCHARS + CODE_UCHARS);
    var c3 = generateCode(1, CODE_LCHARS + CODE_UCHARS);

    str = btoa(encodeURIComponent(str));
    var mid = str.length / 2;

    return c1 + str.substr(0, mid) + c2 + str.substr(mid) + c3;
}