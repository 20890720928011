//write global plain javascript functions here...

var loadingGroupCount = {};
function loading(status, opacity, selector, groupName) {
    /* WEB-2840 AMS: EOIs page - Missing Loading Indicator
    * Need to allow loading for a group of calls
    * Only hide loading when all calls in a group are finished loading */
    if(groupName){
        if(!status){
            loadingGroupCount[groupName] = loadingGroupCount[groupName] ? (loadingGroupCount[groupName] - 1) : null;
            if(loadingGroupCount[groupName]) return false; // If loading group count > 0 then need to keep showing loading
        }else{
            loadingGroupCount[groupName] = loadingGroupCount[groupName] ? (loadingGroupCount[groupName] + 1) : 1;
        }
    }

    var id = 'div_ss_loading_spinner' + (selector ? '_sub' : '');

    var body = jQuery(selector ? selector : 'body');
    var modal = body.find('#'+id);

    if (modal && modal.length > 0) {
        modal = jQuery(modal[0]);
    } else {
        modal = jQuery('<div/>');
        modal.attr('id', id);
        modal.attr('tabindex', 0);
        modal.css({
            position: selector ? 'absolute' : 'fixed',
            left: '0px',
            top:'0px',
            width: '100%',
            height: '100%',
            overflow: 'auto',
            cursor: 'progress',
            'z-index': '99999',
            'background-image': 'url(/images/ss_loader.gif)',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'background-size': '100px 100px',
            'display': 'none'
        });
        body.append(modal);
    }

    if (status) {
        if (!selector) {
            body.css({overflow: 'hidden'});
        }

        modal.css({
            display: 'block',
            'background-color': 'rgba(0, 0, 0, '+(opacity == null || opacity == undefined ? 0.4 : opacity)+')'
        });

        if (!selector) {
            modal.focus();
        }
    } else {
        if (!selector) {
            body.css({overflow: 'auto'});
        }
        modal.css({display: 'none'});
    }

    modal.keydown(function(e) {
        e.preventDefault();
    });

}

function getFromArray(array, k, v) {
    if (!array || array.length == 0 || !k || !v) return;

    var object = null;
    jQuery.each(array, function(i, o) {
        if (o[k] == v) {
            object = o;
            return false;
        }
    });

    return object;
}