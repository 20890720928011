/**
 * Custom Functions for Browser Compatibility
 *
 * @author Muhammad Taiyab
 * @date 2017-10-11 12:46
 */

/**
 * startsWith
 *
 * @param str1
 * @param str2
 * @returns {boolean}
 */
function startsWith (str1, str2) {
    if (!str1 || !str2)
        return;

    if (typeof str1.startsWith === "function") {
        return str1.startsWith(str2);
    } else if (typeof str1.indexOf === "function") {
        return str1.indexOf(str2) == 0;
    }
}

/**
 * endsWith
 *
 * @param str1
 * @param str2
 * @returns {boolean}
 */
function endsWith (str1, str2) {
    if (!str1 || !str2)
        return;

    if (typeof str1.endsWith === "function") {
        return str1.endsWith(str2);
    } else if (typeof str1.indexOf === "function") {
        return str1.indexOf(str2) == (str1.length - str2.length);
    }
}


/**
 * Returns true if str1 contains str2.
 *
 * @param str1
 * @param str2
 * @param caseSensitive
 * @returns {boolean}
 */
function contains (str1, str2, caseSensitive) {
    if (!str1 || !str2) {
        return;
    }

    var _str1 = str1.toString();
    var _str2 = str2.toString();

    if (!caseSensitive) {
        _str1 = _str1.toLowerCase();
        _str2 = _str2.toLowerCase();
    }

    return _str1.indexOf(_str2) >= 0;
}


/**
 * isInteger
 *
 * @type {*|Function}
 */
Number.isInteger = Number.isInteger || function(value) {
    return typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value;
};

/**
 * find
 *
 * @type {*|Function}
 */
Array.prototype.find = Array.prototype.find || function(f) {
    for(var i=0; i<this.length; i++) {
        if (f(this[i])){
            return this[i];
        }
    }
};

/**
 * window.location.origin
 * Internet Explorer does not have access to window.location.origin
 */
if (!window.location.origin) {
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}