//Disable full page right click
// $(document).ready(function () {
//     $('body').on('contextmenu', function (e) {
//         return false;
//     });
// });

$(document).ready(function() {
    $('.carousel').carousel();
});

// Footer accordion for mobile only
$(function () {
    if ($(window).width() <= 768) {
        $('.link-group-title').click(function () {
            var $this = $(this);
            /* Toggle between adding and removing the 'active' class,
            to highlight the button that controls the panel */
            $this.toggleClass('active');

            /* Toggle between hiding and showing the active panel */
            var $panel = $this.next();
            $panel.stop().slideToggle();
        }).next().hide();
    }
});

// Custom tabs advanced search
function openDistrict(evt, districtName) {
    var i, tabcontent, tablinks;
    $('#mrts .tabcontent').css('display', 'none');
    $('#mrts .tablinks').removeClass('active');
    $('#' + districtName).css('display', 'block');
    evt.currentTarget.className += ' active';
}

// Get the element with id='defaultOpen' and click on it
if($('#defaultOpen') && $('#defaultOpen').length) {
    document.getElementById('defaultOpen').click();
}

// // School Type function
// function openSchoolType(evt, schoolType) {
//     var i, tabcontent, tablinks;
//     $('#schools .tabcontent').css('display', 'none');
//     $('#schools .tablinks').removeClass('active');
//     $('#' + schoolType).css('display', 'block');
//     evt.currentTarget.className += ' active';
// }
//
// // Get the element with id='defaultOpenSchoolType' and click on it
// if($('#defaultOpenSchoolType').length) {
//     document.getElementById('defaultOpenSchoolType').click();
// }
//
// // Drone Function
// function openDrone(evt, droneType) {
//     var i, tabcontent, tablinks;
//     $('.drone-tab-container .tabcontent').css('display', 'none');
//     $('.drone-tab-container .tablinks').removeClass('active');
//     $('#' + droneType).css('display', 'block');
//     evt.currentTarget.className += ' active';
// }
//
// // Get the element with id='defaultOpen' and click on it
// if($('#defaultOpenDrone').length) {
//     document.getElementById('defaultOpenDrone').click();
// }
//
// //Advanced search schools alphabetical order
//
// $('#alphabetic-school-order li a').click(function(e) {
//     e.preventDefault();
//     var id = ($(this).attr('href'));
//     var divPosition = $(id).offset().top;
//     $('html, body').animate({
//         scrollTop: divPosition
//     })
// });

// Scroll to top button animation
if ($('#back-to-top')) {
    $('#back-to-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 1000);
    });
}

// collapse dropdown on click outside
$(document).click(function(e) {
    if (!$(e.target).is('a')) {
        $('.collapse').collapse('hide');
    }
});