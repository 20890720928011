// Constants ------------------------------------

const DIGITS = '23456789';
const LCHARS = 'abcdefghjkmnpqrstwxyz';
const UCHARS = 'ABCDEFGHJKLMNOPQRSTWXYZ';

const CODE_DIGITS = 1;
const CODE_LCHARS = 2;
const CODE_UCHARS = 4;

const CONDITION_AT_LEAST_ONE_DIGIT = 1;
const CONDITION_AT_LEAST_ONE_CHAR = 2;

// Functions ------------------------------------

/**
 * Generates a random password using digits and lower/upper case letters
 *
 * @param int length
 * @return string
 */
function generatePassword(length) {
    length = length || 8;
    return generateCode(length, CODE_DIGITS + CODE_LCHARS + CODE_UCHARS);
}

/**
 * Generates random code
 *
 * @param {number} length - Size of generated code. default = 6
 * @param {number} chartype - The chars used to generate the code. default = 1
 * 1 = Digits,
 * 2 = Lowercase chars,
 * 3 = Digits + Lowercase chars,
 * 4 = Uppercase chars,
 * 5 = Digits + Uppercase chars,
 * 6 = Lowercase + Uppercase chars,
 * 7 = All
 * @param {number} conditions - Custom conditions.
 * 1 = At least one digits,
 * 2 = At least one char,
 * 3 = At least one digit and one char,
 *
 * @return {string}
 */
function generateCode(length, chartype, conditions) {
    length = length || 8;
    chartype = chartype || 1;
    conditions = conditions || null;

    // Prepare characters
    var characters = '';

    // TODO: improve comparison check
    if ([1, 3, 5, 7].indexOf(chartype) != -1) {
        characters += DIGITS;
    }
    if ([2, 3, 6, 7].indexOf(chartype) != -1) {
        characters += LCHARS;
    }
    if ([4, 5, 6, 7].indexOf(chartype) != -1) {
        characters += UCHARS;
    }

    var charactersLength = characters.length;

    // Generate code
    var code = '';
    for (var i = 0; i < length; i++) {
        code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    // Check conditions
    if (conditions) {
        if ([1, 3].indexOf(conditions) != -1) {
            // Check if contains a digit
            if (!/\d/.test(code)) {
                code = generateCode(length, chartype, conditions);
            }
        }
        if ([2, 3].indexOf(conditions) != -1) {
            // Check if contains a letter
            if (!/[a-zA-Z]/.test(code)) {
                code = generateCode(length, chartype, conditions);
            }
        }
    }

    return code;
}
