/**
 * Fetch videos from Youtube channel
 * @author Muhammad Taiyab
 * @date 2017-06-22
 */

// Code moved to PHP file
// var YOUTUBE_API_KEY = '__YOUTUBE_API_KEY__';
// var YOUTUBE_API_BASE_URL = 'https://www.googleapis.com/youtube/v3';
//
//
// function _get_youtube_channel(id, callback) {
//     var url = YOUTUBE_API_BASE_URL + '/channels';
//     var params = {
//         key: YOUTUBE_API_KEY,
//         //part: 'brandingSettings,snippet,statistics,contentDetails',
//         part: 'contentDetails',
//         id: id
//     };
//     jQuery.getJSON(url, params).done(function (data) {
//         callback(data);
//     });
// }
//
// function _get_youtube_channel_playlists(id, callback) {
//     var url = YOUTUBE_API_BASE_URL + '/playlists';
//     var params = {
//         key: YOUTUBE_API_KEY,
//         part: 'contentDetails,snippet,contentDetails',
//         channelId: id,
//         maxResults: 25
//     };
//     jQuery.getJSON(url, params).done(function (data) {
//         callback(data);
//     });
// }
//
// function _get_youtube_playlist_items(id, callback) {
//     var url = YOUTUBE_API_BASE_URL + '/playlistItems';
//     var params = {
//         key: YOUTUBE_API_KEY,
//         part: 'contentDetails,snippet',
//         playlistId: id,
//         maxResults: 6
//     };
//     jQuery.getJSON(url, params).done(function (data) {
//         callback(data);
//     });
// }
//
// function _get_youtube_videos(ids, callback) {
//     var url = YOUTUBE_API_BASE_URL + '/videos';
//     var params = {
//         key: YOUTUBE_API_KEY,
//         part: 'contentDetails,statistics,snippet',
//         id: ids
//     };
//     jQuery.getJSON(url, params).done(function (data) {
//         callback(data);
//     });
// }
//
//
// function get_youtube_channel_uploads(channelId, callback) {
//     _get_youtube_channel(channelId, function(channel) {
//
//         if (!channel.items || channel.items.length === 0)
//             callback();
//
//         var playListId = channel.items[0].contentDetails.relatedPlaylists.uploads;
//
//         _get_youtube_playlist_items(playListId, function (playlist) {
//             if (!playlist.items || playlist.items.length === 0)
//                 callback();
//
//             var videoIds = '';
//             jQuery.each(playlist.items, function (i, video) {
//                 videoIds += i > 0 ? ',' : '';
//                 videoIds += video.contentDetails.videoId;
//             });
//
//             _get_youtube_videos(videoIds, function (videos) {
//
//                 jQuery.each(playlist.items, function (i, video1) {
//                     jQuery.each(videos.items, function (j, video2) {
//                         if (video1.contentDetails.videoId === video2.id) {
//                             video1.statistics = video2.statistics;
//                         }
//                     });
//                 });
//
//                 callback(playlist);
//             });
//
//         });
//     });
// }
//
// function get_youtube_channel_all_playlist_videos(channelId, callback) {
//     _get_youtube_channel_playlists(channelId, function (channel) {
//
//         if (!channel.items || channel.items.length === 0)
//             callback();
//
//         var completed = 0;
//         jQuery.each(channel.items, function (i, playlistContents) {
//             get_youtube_playlist_videos(playlistContents.id, function (playlistItems) {
//                 playlistContents.items = playlistItems;
//                 completed += 1;
//                 if (completed == channel.items.length) {
//                     callback(channel);
//                 }
//             });
//         });
//     });
// }
//
// function get_youtube_playlist_videos(playlistId, callback) {
//     _get_youtube_playlist_items(playlistId, function (playlist) {
//         if (!playlist.items || playlist.items.length === 0)
//             callback();
//
//         var videoIds = '';
//         jQuery.each(playlist.items, function (i, video) {
//             videoIds += i > 0 ? ',' : '';
//             videoIds += video.contentDetails.videoId;
//         });
//
//         _get_youtube_videos(videoIds, function (videos) {
//
//             jQuery.each(playlist.items, function (i, video1) {
//                 jQuery.each(videos.items, function (j, video2) {
//                     if (video1.contentDetails.videoId === video2.id) {
//                         video1.statistics = video2.statistics;
//                     }
//                 });
//             });
//
//             callback(playlist);
//         });
//
//     });
// }


function hide_youtube_video_player(videoId) {
    var div_id = 'div_youtube_video_player';

    var div = jQuery('#' + div_id);
    if (div.length) {
        div.remove();
    }
}

function toggle_youtube_video_player(videoId) {
    var div_id = 'div_youtube_video_player';

    var div = jQuery('#' + div_id);
    if (div.length) {
        div.remove();
        return;
    }

    var html = '';

    html += '<div id="' + div_id + '" style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: rgba(0,0,0,0.9);" onclick="hide_youtube_video_player(\'' + videoId + '\')">';
    html += ' <i class="icon icon-reject" style="position: absolute; top: 10px; right: 10px; cursor: pointer; color: #fff;" onclick="hide_youtube_video_player(\'' + videoId + '\')"></i>';
    html += ' <div style="width: 100%; height: 100%; position: absolute; top: 5%; text-align: center;  onclick="hide_youtube_video_player(\'' + videoId + '\')">';
    html += '  <iframe style="width: 90%; height: 90%;" src="https://www.youtube.com/embed/' + videoId + '?rel=0&autoplay=1" frameborder="0" allowfullscreen=""></iframe>';
    html += ' </div>';
    html += '</div>';

    jQuery('body').append(jQuery(html));
}
