/*
 * Methods for opening and closing search overlay
 * */

/* Open */
function openSearchNav() {
  document.getElementById("seatchNav").style.height = "100%";
  document.getElementById("seatchNav").style.width = "100%";
  document.getElementById("seatchNav").style.right = "0%";
  document.getElementById("search-overlay-input").focus();
}

/* Close */
function closeSearchNav() {
  document.getElementById("seatchNav").style.height = "0%";
  document.getElementById("seatchNav").style.width = "0%";
  document.getElementById("seatchNav").style.right = "50%";
}


/*
 * Search Overlay Code Ending
 * */